import { Component, OnInit } from '@angular/core';
import {faPhone} from '@fortawesome/free-solid-svg-icons/faPhone';
import {faMailBulk} from '@fortawesome/free-solid-svg-icons/faMailBulk';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons/faEnvelope';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  faPhone = faPhone;
  faMail = faEnvelope;

  constructor() { }

  ngOnInit(): void {
  }

}
