<div class="container-fluid bg-black position-relative">
  <div class="container pt-5 pb-5">
    <div class="row c-white">
      <div class="col-md-12 text-center">
        <img src="assets/images/logo.jpg" width="175px"/>
      </div>

      <!-- pages -->
      <div class="col-md-3 mt-4">
        <h3>Pagina's</h3>
        <ul class="list-unstyled ">
          <li class="d-block  c-pointer " routerLink="">Home</li>
          <li class="d-block   c-pointer" [routerLink]="['permanente-make-up']" href="#">Permanente make-up</li>
          <li class="d-block   c-pointer" [routerLink]="['YAG-laser']" href="#">YAG laser</li>
          <li class="d-block   c-pointer" [routerLink]="['EM-Fitness']" href="#">EM Fitness</li>
          <li class="d-block   c-pointer" [routerLink]="['prijzen']"  >Behandelingen & Prijzen</li>
          <li class="d-block   c-pointer " routerLink="prijzen">Prijzen</li>
          <li class="d-block   c-pointer " routerLink="over-ons">Over ons</li>
          <li class="d-block   c-pointer " routerLink="contact">Contact</li>
        </ul>
      </div>

      <!-- Openingstijden -->
      <div class="col-md-3 mt-4">
        <h3>Openingstijden</h3>
        <p>Maandag t/m Vrijdag: 9.00-21.30<br/>
        Zaterdag: 9.00-17.00<br/>
          Zondag: Gesloten</p>
        <a href="https://zonnestudio-roombeek.salonized.com/widget_bookings/new" target="_blank"><button class="secondary-button default-button">Maak een afspraak</button></a>
      </div>

      <!-- Social media -->
      <div class="col-md-3 mt-4">
        <h3>Social media</h3>
        <a href="https://www.facebook.com/zonnebank.roombeek/" target="_blank"><img src="assets/images/facebook.svg"/></a>
        <a href="https://www.instagram.com/studioroombeek/" class="ml-2" target="_blank"><img src="assets/images/instagram.svg"/></a>
      </div>

      <!-- Contact -->
      <div class="col-md-3 mt-4">
      <h3>Contact</h3>
        <p>Roomweg 89<br/>
          7523 BM, Enschede<br/>
          T: <a href="tel:053-4340297" class="link-unstyled">053-4340297</a><br/>
          E: <a href="mailto:info@zsroombeek.nl" class="link-unstyled">info@zsroombeek.nl</a></p>
      </div>

    </div>
  </div>

  <div class="stage">
    <img src="assets/images/erkend_leer_bedrijf.png" class="logo_stage"/>
  </div>

</div>
<div class="container-fluid bg-dark text-center p-2 c-white">
  © {{year}} Zonnestudio Roombeek | Website onderhouden en gerealiseerd door Joey Jreij
</div>


