<!-- header -->
<div class="container-fluid header d-flex align-items-center">
    <div class="container position-relative my-auto py-5 text-center">

        <!-- title -->
        <div class="my-5 py-5">
            <span class="display-4 bg-white c-black text-center text-uppercase p-2 mt-5 mb-5">YAG Laser</span>
        </div>

    </div>
</div>


<div class="container-fluid">
    <div class="container pt-5 pb-5">
<div class="row">
    <div class="col-md-4 order-1 order-md-0">
        <img src="assets/images/yag-apparaat.jpg" width="100%"/>
    </div>
    <div class="col-md-8  order-0 order-md-1">
        <h1 class="font-weight-bold">Wat doet de YAG laser?</h1>
        <span>
            Ecuri YAG laser is een Q-switched ND YAG laser die kunstmatig ingebracht pigment (tattoo of oude PMU) en natuurlijke melanine kan verwijderen. Al deze pigmenten worden tot hele kleine stukjes geschoten (gefragmenteerd) door het laser licht. Een groot deel van het pigment wordt direct afgestoten tijdens de behandeling. Een deel wordt afgestoten tijdens de korstvorming en de wondgenezing. Een deel wordt opgenomen door het lymfe systeem en verlaat zo het lichaam. Er zijn echter grote verschillen afhankelijk van het gebruikte kleurstof, hoeveelheid ingebrachte kleurstof en de persoonlijke constitutie van de huid.
        </span>
        <br/>
        <br/>
        <p class="font-weight-bold">De voordelen</p>
        <span>
            Met de Ecuri YAG laser kunt u in een korte tijd verlost worden van uw pigmentvlekken, ouderdomsvlekken, couperose, tattoo of permanente make-up zonder de huid daarnaast te beschadigen. Zo werkt u doelgericht met een vlug resultaat. Bent u niet tevreden over de kleur, vorm of plaatsing van uw permanente make-up? Door middel van de YAG laser is het mogelijk om vrijwel pijnloos foute permanente make-up te verwijderen of te verzachten. Nadat de huid genezen is kunnen wij eventueel weer nieuwe mooie permanente make-up plaatsen. Vind u de vorm van bijvoorbeeld uw permanente make-up wenkbrauwen wel mooi maar de kleur te donker, wij kunnen ze met 1 of 2 behandelingen lichter en zachter maken.
        </span>
        <br/>
        <br/>
        <p class="font-weight-bold">De eerste behandeling en aantal behandelingen die nodig zijn</p>
        <span>
            Omdat wij graag willen zien hoe uw huid op de laser reageert, zullen wij tijdens de intake en voor de daadwerkelijke behandeling eerst een testflits uitvoeren. De hoeveelheid behandelingen die nodig zijn een gewenst resultaat te behalen is volledig afhankelijk van het type pigment en het huidtype. Lastig is daarom van te voren te bepalen hoeveel behandelingen er nodig zullen zijn. Soms is het beter bij de eerste keer de machine minder krachtig te zetten om te kijken hoe de huid reageert, geneest en om huidbeschadigingen te voorkomen. Dit doen we meestal wanneer de huid al gevoelig is. We willen immers de huid verfraaien en niet aantasten.
        </span>
        <br/>
        <br/>
        <p class="font-weight-bold">Pijn</p>
        <span>
            De gevoeligheid van de zenuwuiteinden in de huid verschilt per persoon en lichaamsdeel. Wat u voelt is een warme prikkelende tinteling. Laser behandelingen zijn tegenwoordig goed te ondergaan en vrijwel pijnloos. Wanneer u extra gevoelig bent voor pijnprikkels mag u 1 uur voor de behandeling 1 of 2 Paracetamol innemen (GEEN andere pijnstillers innemen).
        </span>
        <br/>
        <br/>
        <p class="font-weight-bold">Voor de behandeling </p>
        <span>
            Indien u in het gezicht behandeld wordt breng dan geen make-up aan voor de behandeling. 7 dagen voor de behandeling raden wij intensief zonnen (zon of zonnebank) af. Gebruik minstens 5 dagen voor de behandeling geen zelfbruiners. Uw haren moeten indien ze lang zijn vast in een staart of knot. Draag voor de behandeling witte kleding (hemd en/of witte BH). Meldt tatoeages of permanente make-up die niet behandeld hoeven te worden. Deze dienen wij indien nodig af te dekken.
        </span>
        <br/>
        <br/>
        <p class="font-weight-bold">
            Ontharen tussen de behandelingen </p>
        <span>
            Het verwijderen van haartjes met pincet, draad, harsen, Epilady of enig ander epilerend apparaat is verboden omdat u dan de haren verwijderd die juist de warmte moeten gaan geleiden.
        </span>
        <br/>
        <br/>
        <p class="font-weight-bold">
            Medicijnen / ziekte  </p>
        <span>
            Contra-indicaties zijn: medicatie als hormonen, corticosteroïden, bepaalde lichtgevoelige medicijnen (zie bijsluiter onder fotosensibiliteit), maar ook huid- en/of aderontsteking, huidkanker, kanker, open wonden, epilepsie, suikerziekte, zwangerschap en gebruik van bruin zonder zon medicatie. Deze en alle andere indicaties dient u ons te melden. Raadpleeg bij twijfel (ook bij misschien kwaadaardige pigmentvlekken) altijd uw (huis)arts en vraag om toestemming.<br/><br/>
Hormoonproblemen: veroorzaakt door pubertijd, zwangerschap, borstvoeding, menopauze, bijnieren en eierstokontsteking geven nieuwe pigment ontwikkeling. Daarom zijn soms (na langere tijd) nabehandelingen nodig om het resultaat vast te houden.
        </span>
        <br/>
        <br/>
    </div>
    </div></div>
</div>

