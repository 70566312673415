<!-- header -->
<div class="container-fluid homepage-header d-flex align-items-center position-relative overflow-hidden">

    <video autoplay loop muted playsinline class="video-header"
           src="assets/images/barbervideo.MOV" data-object-fit="cover"></video>

    <div class="container position-relative my-auto">

        <div class="row">

            <div class="col-md-9 col-lg-12">

                <!-- title -->
                <span class="display-2 bg-white c-black text-center text-uppercase p-2">KAPPER</span>
                <p class="c-white mt-2 display-4">Dames en heren knippen, wassen, föhnen, highlights, balayage en meer</p>

                <!-- button -->
                <a href="https://zonnestudio-roombeek.salonized.com/bookings/new" target="_blank">
                    <button class="default-button primary-button button-big mb-4 mt-0 mt-md-3 ml-lg-2">Ik wil een online
                        afspraak maken
                    </button>
                </a>

            </div>

        </div>

    </div>

</div>


<div class="container-fluid">
    <div class="container pt-5 pb-5">
        <span class="display-4 bg-white c-black text-center text-uppercase pl-0 pr-2 py-2">Over ENVY</span>
        <div class="row c-white">

            <div class="col-md-6 mt-4 color-black c-black">
                <p>
                    Wanneer je werk en dromen samenkomen… Met trots presenteer ik ENVY!
                    <br/><br/>
                    Voor wie mij nog niet kent, mijn naam is Nadine Vaartjes en ik werk al ruim 6 jaar als kapster. De afgelopen jaren heb ik veel geleerd over het kappersvak en veel ervaring opgedaan. Ik blijf mij ontwikkelen.
                    <br/><br/>
                    ENVY Hairstylist staat voor goed advies, oprechte aandacht en natuurlijk de perfecte kleur en styling. Het is mijn passie om jouw haar te laten stralen!
                    <br/><br/>
                    Liefs,<br/>
                    NV

                    <a href="tel:++31 6 20208347" class="c-black d-block">+31 6 20208347</a>
                </p>
                <a href="https://www.instagram.com/envyhairstylist/" class="ml-2" target="_blank"><img src="assets/images/igblack.svg"/></a>
                <a href="https://zonnestudio-roombeek.salonized.com/bookings/new" target="_blank">
                    <button class="default-button primary-button button-big mb-4 mt-3 mt-md-3 d-block">Ik wil een afspraak maken</button>
                </a>

            </div>

            <div class="col-md-6 mt-4 ">
                <img src="assets/images/prijslijst.jpeg" width="100%"/>
            </div>

        </div>
    </div>
</div>
