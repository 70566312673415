<!-- header -->
<div class="container-fluid contact-header d-flex align-items-center">
    <div class="container position-relative my-auto py-5 text-center">

        <!-- title -->
        <div class="my-5 py-5">
            <span class="display-4 bg-white c-black text-center text-uppercase p-2 mt-5 mb-5">Afspraak maken of een vraag?</span>
        </div>

    </div>
</div>

<div class="container-fluid pt-5 pb-5">
    <div class="container">
        <div class="row">
            <!-- title -->
            <div class="col-md-12">
                <span class="display-4 bg-black c-white text-center text-uppercase p-2">Algemene informatie</span>
            </div>

            <div class="col-md-6 pt-4">
                <h1>Adress</h1>
<p>Roomweg 89<br/>
    7523 BM, Enschede<br/>
    T: 053-4340297<br/>
    E: info@zsroombeek.nl</p>

                <h1 class="mt-5">Afspraak maken</h1>
                <!-- button -->
                <a href="https://zonnestudio-roombeek.salonized.com/widget_bookings/new" target="_blank">
                    <button class="default-button primary-button button-big mb-4 mt-0 mt-md-1">Ik wil een online
                        afspraak maken
                    </button>
                </a>

            </div>

            <div class="col-md-6">
                <iframe width="100%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.nl/maps?q=zonnestudioroombeek&amp;ie=UTF8&amp;hq=&amp;hnear=&amp;&amp;spn=0.006295,0.006295&amp;t=m&amp;output=embed"></iframe>
            </div>


        </div>
    </div>
</div>

<app-contact-component></app-contact-component>

