<!-- header -->
<div class="container-fluid pricing-header d-flex align-items-center">
    <div class="container position-relative my-auto py-5 text-center">

        <!-- title -->
        <div class="my-5 py-5">
            <span class="display-4 bg-white c-black text-center text-uppercase p-2 mt-5 mb-5">Prijzen</span>
        </div>

    </div>
</div>


<div class="container-fluid">
    <div class="container pt-5 pb-5">
        <p class="text-center">
            Bij Studio Roombeek kunt u heel gemakkelijk zelf online uw afspraak inplannen. Selecteer de juiste
            behandeling(en), gewenste medewerker en dag/tijdstip. Na het boeken ontvangt u een e-mail met de bevestiging
            van uw afspraak. 48 uur voor de behandeling ontvangt u nog een herinnering van ons.
            <br/><br/>
            Bij het selecteren van meerdere behandelingen kan het wel eens voorkomen dat de agenda geen enkele
            mogelijkheid aangeeft terwijl wij wel plek hebben. Dit heeft te maken met de verschillende behandelingen,
            werktijden en salonruimtes. Mocht dit gebeuren kunt u zelf online de behandelingen los van elkaar boeken of
            telefonisch contact met ons opnemen.
            <br/><br/>
            Komt u er online niet uit? Neem dan gerust telefonisch contact met ons op, wij helpen u graag! 053-4340297.
        </p>
        <br/>
        <br/>
        <ng-container *ngIf="loaded">
            <div class="salonized-booking" data-company="cpganh5PWqcVJV4FmV4yjNpD" data-color="#000000" data-language="nl"
                 data-height="700" data-inline=true></div>
        </ng-container>
    </div>
</div>

<app-contact-component></app-contact-component>
