import {Component, Inject, OnInit} from '@angular/core';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    this.document.location.href = 'https://zonnestudio-roombeek.salonized.com/widget_bookings/new';
  }

}
