<!-- header -->
<div class="container-fluid header d-flex align-items-center position-relative overflow-hidden">

        <video autoplay loop muted playsinline class="video-header"
               src="assets/images/video-fitness.mov" data-object-fit="cover"></video>



    </div>




<div class="container-fluid">
    <div class="container pt-5 pb-5">
        <div class="row">
            <div class="col-md-4 order-1 order-md-0">
                <img src="assets/images/EM.jpg" width="100%"/>
            </div>
            <div class="col-md-8  order-0 order-md-1">
                <h1 class="font-weight-bold">Behaal sneller uw doelen met EM Fitness! </h1>
                <span>
            Sport u met tegenzin? duurt uw doel die u wilt behalen te lang en wilt u sneller resultaat? Met EM Fitness kunt u spieren op bijvoorbeeld uw buik en billen duurzaam opbouwen en onderhouden.
                    <br/><br/>
De behandelingen vervangen geen gezonde en evenwichtige levensstijl. Ze ondersteunen en helpen u om uw eigen doelen en verlangens op het gebied van vetreductie, spieropbouw en lichaamsvorming sneller en efficiënter te bereiken.
                    <br/><br/>
De behandeling (van 30 minuten) werkt als ongeveer 25.000 sit-ups of in geval van de buik- of glutiale spieren. De energie induceert bijna 25.000 supramaximale spiercontracties per sessie. Dus een training welke zelfs in het dagelijks leven niet kan worden gerealiseerd en alleen op deze wijze op de spiergebieden kan worden behandeld.
                    <br/><br/>

Met de verschillende standen van de EM Fitness apparaat kunnen wij uw behandeling samenstellen en helpen uw doel te bereiken. De standen zijn o.a. voor het vasthouden van vocht, spieropbouw, vetreductie en Cellulitis (putjes).
                    <br/><br/>
Standen: huid liften, intensieve spieropbouw, intensieve vetreductie, lichaam shaping, intensieve bloed circulatie en lymfe activatie, rustmodes (om de behandeling mee af te sluiten).
        </span>
                <br/><br/>
                <p class="font-weight-bold">Behandelzones:</p>
                <ul>
                    <li>Buik (bijv. buikspieren, buiklift, vetreductie)</li>
                    <li>Billen (bijv. Butt lift, rondere billen, Cellulitis)</li>
                    <li>Armen (bijv. armvet, kipfilet armen, armspieren)</li>
                    <li>Boven benen (bijv. beenspieren, vetreductie, Cellulitis)</li>
                    <li>Onderrug spieren (bijv. voor rug ondersteuning, spieropbouw, rugklachten).</li>

                </ul>
                <br/>
                <br/>
                <span>
                    Voor een zichtbaar resultaat raden wij 4 behandelingen aan. Nadat u tevreden bent over het resultaat kunt u tussen de 3-6 maanden een behandeling herhalen om het resultaat te behouden. Als u na de EM Fitness behandelingen blijft sporten 6-12 maanden.

                </span>
            </div>
        </div></div>
</div>

