<!-- header -->
<div class="container-fluid schoonheidssalon-header d-flex align-items-center">
    <div class="container position-relative my-auto py-5 text-center">

        <!-- title -->
        <div class="my-5 py-5">
            <span class="display-4 bg-white c-black text-center text-uppercase p-2 mt-5 mb-5">Schoonheidssalon</span>
        </div>

    </div>
</div>
<app-salon-component></app-salon-component>
<div class="container-fluid">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <a href="https://zonnestudio-roombeek.salonized.com/bookings/new" target="_blank">
                    <button class="default-button primary-button mb-4 mt-0 mt-md-3 ml-lg-2">Maak nu een online afspraak!
                    </button>
                </a>
            </div>
        </div>
    </div>
</div>
<app-contact-component></app-contact-component>

