import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {PricingComponent} from './pages/pricing/pricing.component';
import {SalonComponent} from './pages/salon/salon.component';
import {ContactComponent} from './pages/contact/contact.component';
import {AboutComponent} from './pages/about/about.component';
import {AppointmentComponent} from './pages/appointment/appointment.component';
import {BarberComponent} from './pages/barber/barber.component';
import {LaserComponent} from './pages/laser/laser.component';
import {MakeupComponent} from './pages/makeup/makeup.component';
import {FitnessComponent} from './pages/fitness/fitness.component';


const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'prijzen', component: PricingComponent },
  { path: 'salon', component: SalonComponent },
  { path: 'over-ons', component: AboutComponent },
  { path: 'permanente-make-up', component: MakeupComponent },
  { path: 'YAG-laser', component: LaserComponent },
  { path: 'EM-Fitness', component: FitnessComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'afspraak-maken', component: AppointmentComponent },
  { path: 'kapper', component: BarberComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
