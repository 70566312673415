<div class="container-fluid bg-light">

<div class="container pt-5 pb-5">

  <!-- title -->
  <div class="row">

    <div class="col-md-6 mt-4 position-relative">
      <p class="display-4 bg-black c-white text-center text-uppercase p-2">Contact </p>

      <a href="tel:053-4340297">
      <button class="default-button primary-button mb-2 mb-md-4 mt-3 d-md-inline-block d-block"><fa-icon [icon]="faPhone"></fa-icon> 053-4340297</button>
      </a>

      <a href="mailto:info@zsroombeek.nl">
        <button class="default-button tertiary-button mb-4 mt-0 mt-md-3 ml-lg-2"><fa-icon [icon]="faMail"></fa-icon> info@zsroombeek.nl</button>
      </a>

    </div>

    <div class="col-md-6 mt-4 position-relative text-right d-none d-md-block">
        <img src="./assets/images/contact-component.jpg" width="50%"/>
    </div>

  </div>

</div>
</div>
