<!-- header -->
<div class="container-fluid salon-header position-relative">
  <div class="container position-relative h-100">
    <!-- title -->
    <span class="display-4 bg-white c-black text-uppercase p-2 title-about position-absolute">Schoonheidspecialistes</span>
  </div>
</div>

<div class="container mt-5">

  <!-- title -->
  <span class="display-4 bg-black c-white text-center text-uppercase p-2">Team</span>

  <div class="row">

    <div class="col-6 col-md-3 col-sm-6 mt-4 position-relative">
      <img src="./assets/images/hilde.jpg" width="100%"/>
      <h3 class="position-absolute team-name c-white">Hilde</h3>
    </div>

<!--    <div class="col-6 col-md-3 col-sm-6 mt-4 position-relative">-->
<!--      <img src="./assets/images/juliet.jpg" width="100%"/>-->
<!--      <h3 class="position-absolute team-name c-white">Juliët</h3>-->
<!--    </div>-->

    <div class="col-6 col-md-3 col-sm-6 mt-4 position-relative">
      <img src="./assets/images/lotte.jpg" width="100%"/>
      <h3 class="position-absolute team-name c-white">Lotte</h3>
    </div>

    <div class="col-6 col-md-3 col-sm-6 mt-4 position-relative">
      <img src="./assets/images/charell.jpg" width="100%"/>
      <h3 class="position-absolute team-name c-white">Charell</h3>
    </div>

    <div class="col-6 col-md-3 col-sm-6 mt-4 position-relative">
      <img src="./assets/images/robbin.jpg" width="100%"/>
      <h3 class="position-absolute team-name c-white">Robbin</h3>
    </div>


<!--    <div class="col-6 col-md-3 col-sm-6 mt-4 position-relative">-->
<!--      <img src="./assets/images/Nadine.jpg" width="100%"/>-->
<!--      <h3 class="position-absolute team-name c-white">Nadine (Kapster)</h3>-->
<!--    </div>-->



  </div>



</div>

<div class="container mt-5 mb-5">

  <!-- title -->
  <span class="display-4 bg-black c-white text-center text-uppercase p-2">Onze salon</span>

  <div class="row">

    <div class="col-6 col-md-3 col-sm-6 mt-4 position-relative">
    <a href="./assets/images/IMG_7241.jpg" data-lightbox="image-1">
      <img src="./assets/images/IMG_7241.jpg" width="100%"/>
    </a>
    </div>

    <div class="col-6 col-md-3 col-sm-6 mt-4 position-relative">
      <a href="./assets/images/foto2.jpeg" data-lightbox="image-1">
        <img src="./assets/images/foto2.jpeg" width="100%"/>
      </a>
    </div>



  </div>

</div>

<app-contact-component></app-contact-component>
