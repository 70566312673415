import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-barber',
  templateUrl: './barber.component.html',
  styleUrls: ['./barber.component.scss']
})
export class BarberComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
