<!-- schoonheidssalon -->
<div class="container mt-5 mb-3">

    <div class="row">

        <!-- title -->
<!--        <div class="col-md-12">-->
<!--            <span class="display-4 bg-black c-white text-center text-uppercase p-2">Schoonheidssalon </span>-->
<!--        </div>-->

    </div>

    <div class="row  flex-md-wrap flex-row flex-nowrap mb-2 overflow-auto">

        <div class="col-8 col-md-3 mt-4 position-relative ">
            <img src="./assets/images/schoonheidssalon.jpg" width="100%"/>
            <h3 class="position-absolute service-name c-white">Schoonheidssalon</h3>
        </div>


        <div class="col-8 col-md-3 mt-4 position-relative ">
            <img src="./assets/images/nagels.jpg" width="100%"/>
            <h3 class="position-absolute service-name c-white">Nagelstudio</h3>
        </div>

        <div class="col-8 col-md-3 mt-4 position-relative ">
            <img src="./assets/images/kapsalon.jpg" width="100%"/>
            <h3 class="position-absolute service-name c-white">Kapsalon</h3>
        </div>

        <div class=" col-8 col-md-3 mt-4 position-relative ">
            <img src="./assets/images/zonnestudio.jpg" width="100%"/>
            <h3 class="position-absolute service-name c-white">Zonnestudio</h3>
        </div>


    </div>

</div>
