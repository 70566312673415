<!-- header -->
<div class="container-fluid homepage-header d-flex align-items-center position-relative overflow-hidden">

<!--    <video autoplay loop muted playsinline class="video-header"-->
<!--           src="assets/images/header_homepage.mp4" data-object-fit="cover"></video>-->

    <div class="container position-relative my-auto">

        <div class="row">

            <div class="col-md-9 col-lg-12">

                <!-- title -->
                <span class="display-2 bg-white c-black text-center text-uppercase p-2">Welkom bij Studio Roombeek</span>
                <p class="c-white mt-2 display-4">Bij Studio Roombeek staan wij bekend om onze persoonlijke aandacht, goed advies en kwaliteit voor een betaalbare prijs.</p>

                <!-- button -->
                <a href="https://zonnestudio-roombeek.salonized.com/widget_bookings/new" target="_blank">
                    <button class="default-button primary-button button-big mb-4 mt-0 mt-md-3 ml-lg-2">Ik wil een online
                        afspraak maken
                    </button>
                </a>

            </div>

        </div>

    </div>

</div>


<app-salon-component></app-salon-component>

<div class="container">
    <div class="row mt-4">
        <div class="col-md-12">
            <a routerLink="/over-ons">
                <button class="default-button primary-button mb-4 mt-0 mt-md-3 ml-lg-2">Meer over ons
                </button>
            </a>
        </div>
    </div>
</div>

<div class="container-fluid bg-black">
    <div class="container pt-5 pb-5">
        <span class="display-4 bg-white c-black text-center text-uppercase p-2">Zonnebanken</span>
        <div class="row c-white">

            <div class="col-md-12 mt-4">
                <h1>Prijzen</h1>
            </div>

            <div class="col-md-6 mt-4 ">
                <div class="mb-5">
                    <p>Luxura x7</p>
                    <div class="row">
                        <div class="col">- 10 minuten</div>
                        <div class="col text-right"> €9,00</div>
                    </div>
                    <div class="row">
                        <div class="col">- 15 minuten</div>
                        <div class="col  text-right"> €10,00</div>
                    </div>
                    <div class="row">
                        <div class="  col">- 20 minuten</div>
                        <div class="  col text-right"> €11,00</div>
                    </div>
                </div>
                <div class="mb-5">
                    <p>Luxura x7 turbo</p>
                    <div class="row">
                        <div class="col">- 10 minuten</div>
                        <div class="col text-right"> €9,50</div>
                    </div>
                    <div class="row">
                        <div class="col">- 15 minuten</div>
                        <div class="col text-right"> €10,50</div>
                    </div>
                    <div class="row">
                        <div class="col">- 20 minuten</div>
                        <div class="col text-right"> €11,50</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mt-4">
                <div class="mb-5">
                    <p>Luxura x10</p>
                    <div class="row">
                        <div class="col">- 10 minuten</div>
                        <div class="col text-right"> €10,00</div>
                    </div>
                    <div class="row">
                        <div class="col">- 15 minuten</div>
                        <div class="col  text-right"> €11,00</div>
                    </div>
                    <div class="row">
                        <div class="  col">- 20 minuten</div>
                        <div class="  col text-right"> €12,00</div>
                    </div>
                </div>
            </div>

            <div class="col-md-12">
                <a routerLink="/prijzen">
                    <button class="secondary-button default-button">
                        Naar alle prijzen
                    </button>
                </a>
            </div>

        </div>
    </div>
</div>

<app-contact-component></app-contact-component>
