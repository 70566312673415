import { Component, OnInit } from '@angular/core';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons/faEnvelope';
import {faPhone} from '@fortawesome/free-solid-svg-icons/faPhone';

@Component({
  selector: 'app-contact-component',
  templateUrl: './contact-component.component.html',
  styleUrls: ['./contact-component.component.scss']
})
export class ContactComponentComponent implements OnInit {

  faPhone = faPhone;
  faMail = faEnvelope;


  constructor() { }

  ngOnInit(): void {
  }

}
