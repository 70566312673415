
<!-- topbar -->
<div class="container-fluid bg-black ">
  <div class="container">
  <div class="row py-2">

    <!-- openingstijden -->
    <div class="col-md-6 d-none d-md-block">
      <p class="c-white my-auto">Openingstijden: Ma t/m Vr: 09.00 tot 21.30 & Za: 9.00 tot 17.30 & Zo: gesloten</p>
    </div>

    <!-- Contact infor -->
    <div class="col-md-6">
      <p class="c-white my-auto text-left text-md-right">
        <a href="tel:053-4340297" class="c-white"> <fa-icon [icon]="faPhone"></fa-icon> 053-4340297</a>
        <a href="mailto:info@zsroombeek.nl"  class="c-white ml-3"><fa-icon [icon]="faMail"></fa-icon> info@zsroombeek.nl</a>
      </p>
    </div>
  </div>
  </div>
</div>

<!-- navigation -->
<nav class="navbar navbar-expand-md navbar-dark bg-black">
  <div class="container">
  <a class="navbar-brand " href="#"><img src="assets/images/logo.jpg" class="logo"/></a>




  <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarCollapse">
    <ul class="navbar-nav ml-auto my-auto">

      <li class="nav-item mr-lg-2 my-auto"  routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}">
        <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" [routerLink]="['']">Home</a>
      </li>

      <li class="nav-item dropdown mr-lg-2 my-auto" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show" [routerLinkActiveOptions]="{exact:
true}">
        <a class="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Salon
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" [routerLink]="['permanente-make-up']" href="#">Permanente make-up</a>
          <a class="dropdown-item" [routerLink]="['YAG-laser']" href="#">YAG laser</a>
          <a class="dropdown-item" [routerLink]="['EM-Fitness']" href="#">EM Fitness</a>
          <a class="dropdown-item" [routerLink]="['prijzen']"  >Behandelingen & Prijzen</a>
        </div>
      </li>

<!--      <li class="nav-item  mr-lg-2 my-auto" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show"  [routerLinkActiveOptions]="{exact:-->
<!--true}">-->
<!--        <a class="nav-link" [routerLink]="['/kapper']">Kapper</a>-->
<!--      </li>-->

      <li class="nav-item  mr-lg-2 my-auto" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show" [routerLinkActiveOptions]="{exact:
true}">
        <a class="nav-link" [routerLink]="['over-ons']">Over ons</a>
      </li>

      <li class="nav-item  mr-lg-2 my-auto" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show"  [routerLinkActiveOptions]="{exact:
true}">
        <a class="nav-link" [routerLink]="['/contact']">Contact</a>
      </li>

      <li class="nav-item  mr-lg-2 my-auto " routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}">
        <a class="nav-link" href="https://zonnestudio-roombeek.salonized.com/widget_bookings/new" target="_blank">
        <button class="default-button secondary-button">Maak afspraak!</button>
        </a>
      </li>




    </ul>
  </div></div>

</nav>
<!--    <div class="alert alert-secondary text-center border-0 mb-0">-->
<!--    Let op! Nieuwe klanten dienen eerst contact op te nemen met de salon om een account te krijgen. Hiermee kun je in de toekomst je eigen afspraken inplannen.</div>-->



