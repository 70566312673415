<!-- header -->
<div class="container-fluid header d-flex align-items-center">
    <div class="container position-relative my-auto py-5 text-center">

        <!-- title -->
        <div class="my-5 py-5">
            <span class="display-4 bg-white c-black text-center text-uppercase p-2 mt-5 mb-5">Permanente make-up</span>
        </div>

    </div>
</div>


<div class="container-fluid">
    <div class="container pt-5 pb-5">
        <div class="row">
            <div class="col-md-8">
                <h1 class="font-weight-bold">Over (Semi) Permanente make-up</h1>
                <span>
            Permanente make-up is de manier om uw wenkbrauwen, eyeliner of lipcontour altijd perfect op orde te hebben. Ideaal onder alle omstandigheden, waar u ook bent of wat u ook doet. Of u nou gaat zwemmen, een bezoekje brengt aan de sauna en of u nou lacht of huilt, uw make-up blijft perfect.
            <br/><br/>
            Bij Permanente make-up wordt gebruik gemaakt van een techniek waarbij met een flinterdunne naald pigment (inkt) vlak onder de huid wordt ingezet. De pigmentatie wordt uitgevoerd door een speciale permanente make-up pen. Het resultaat is semi permanent. Dit betekend dat de inkt niet voor altijd zichtbaar zou blijven, zoals bij een tatoeage. Anders dan bij een tatoeage, wordt permanente make-up langzaam lichter. Gemiddeld blijft de pigmentatie 2 jaar goed zichtbaar. De pigmentatie vervaagt geleidelijk en na verloop van tijd is er een touch-up behandeling nodig om de permanente make-up weer op te frissen. Door een touch-up kunt u weer nog langer genieten van onderhoudsvrije make-up.
            <br/><br/>
            De pigmenten die wij gebruiken zijn veilig en betrouwbaar. De kleurstoffen staan onder periodieke controle van de keuringsdienst van Waren.
            <br/><br/>
            De pigmentatie wordt alleen uitgevoerd door gecertificeerde deskundigen.

        </span>
            </div>

            <div class="col-md-4">
                <img src="assets/images/certificaten.JPG" width="60%"/>
            </div>

        </div>
    </div>
</div>


<div class="container-fluid">
    <div class="container pt-5 pb-5">
        <div class="row">
            <div class="col-md-8">
            <h1 class="font-weight-bold">Ombré powder brows</h1>
            <span>
            Ombré powder brows is de nieuwste trend op het gebied van permanente make-up voor wenkbrauwen. Met deze nieuwe techniek van permanente make-up lijkt het alsof uw wenkbrauwen zijn ingekleurd met wenkbrauwpoeder. Het eindresultaat is 40/50% lichter, koeler en zachter dan de pigmentkleur vlak na de eerste behandeling. Bij Ombré powder brows worden de wenkbrauwen op een hele subtiele manier ingeschaduwd met pigment (inkt). De aanzet van de wenkbrauw is licht en luchtig. Het eind van de wenkbrauw (staartje) is scherper, strakker en donkerder (dit hoeft niet). Ombré powder brows kan ook extra soft worden gezet.
        </span>
            <br/><br/>
            <p class="font-weight-bold">Voordelen Ombré powder brows:</p>
            <ul>
                <li>Geen littekens of andere beschadigingen aan de huid.</li>
                <li>Geschikt voor elke huidtype. Ook wanneer u allergie gevoelig bent.</li>
                <li>Ideaal als u weinig wenkbrauwhaartjes heeft of gaten in uw wenkbrauwen.</li>
                <li>De vorm van uw wenkbrauwen wordt duidelijker en makkelijker bij het epileren.</li>
                <li>Blijft gemiddeld 2 jaar goed zichtbaar.</li>
            </ul>
            <br/>
            <p class="font-weight-bold">Correctie behandeling</p>
            <span>Niet helemaal tevreden over de vorm of kleur van de gezette permanente make-up? Met een correctie behandeling bij ons worden de wenkbrauwen opnieuw uitgetekend en gemeten en worden de wenkbrauwen opnieuw gepigmenteerd naar wens.
</span>
        <br/>
        <br/>
            </div>
            <div class="col-md-4">
                <img src="assets/images/ombre.jpg" width="100%"/>
            </div>
        </div>


    </div>
</div>


<div class="container-fluid">
    <div class="container pt-5 pb-5">
        <div class="row">
            <div class="col-md-8">
        <h1 class="font-weight-bold">Permanente make-up Eyeliner </h1>
        <span>
            Het is bekend dat u met permanente make-up veel tijd bespaart. Met permanente make-up kunt u ook denken aan een eyeliner. Staat u iedere ochtend vroeg op om uw eyeliner te tekenen en bent u het helemaal zat? Dan is een permanente make-up eyeliner de oplossing voor u. U wordt wakker met een eyeliner, hoe mooi is dat?
            <br/><br/>
Met een PMU eyeliner brengen we pigment in de huid van uw ooglid aan, vaak aan de wimperrand. U kunt voor een dunne of een wat dikkere eyeliner kiezen maar ook een eyeliner met een korte of lange wing. Een eyeliner geeft optisch meer diepte aan de ogen. Zo kunnen de ogen groter lijken, de wimpers voller en geeft het een frisse blik. U kunt ook voor een eyeliner kiezen tegen de wimperrand van het onderste ooglid.
        </span>
        <br/><br/>
        <p class="font-weight-bold">Voordelen permanente make-up eyeliner:</p>
        <ul>
            <li>Geen irritaties meer wanneer uw eyeliner niet mooi is gelukt.</li>
            <li>De oplossing wanneer u allergie gevoelig bent of gevoelig voor make-up.</li>
            <li>U hoeft niet meer bang te zijn dat uw eyeliner uit loopt.</li>
            <li>Uw eyeliner is altijd symmetrisch.</li>
            <li>Resultaat blijft 3-5 jaar goed zichtbaar.</li>
        </ul>
        <br/>
    </div>

            <div class="col-md-4">
                <img src="assets/images/oog.jpg" width="100%"/>
            </div>

        </div>

    </div>


</div>



<div class="container-fluid">
    <div class="container pt-5 pb-5">
        <div class="row">
            <div class="col-md-8">
                <h1 class="font-weight-bold">Lip blush/ lip pigmentatie</h1>
                <span>
            Lip blush is ook een vorm van permanente make-up. Bij de Lip blush behandeling wordt het lipgebied gepigmenteerd. De naam Lip blush zegt het al, een zachte lip kleur. Het verbetert de vorm van de lippen, geeft definitie en uw lippen lijken voller. Deze subtiele lip kleur ontstaat doordat uw eigen lip kleur met het pigment (inkt) mengt. Hierdoor blijft en lijkt de permanente make-up op de lippen natuurlijk. De lip pigmentatie is vlak na de behandeling nog donker maar geneest 40/50% lichter en zachter.
        </span>
                <br/><br/>
                <p class="font-weight-bold">Voordelen Lip blush</p>
                <ul>
                 <li>Geen fillers nodig, de lippen lijken optisch groter.</li>
                 <li>Natuurlijk en subtiel.</li>
                 <li>Hoeft eventueel alleen nog lipgloss overheen.</li>
                 <li>Doordat het subtiel is kan er nog lippenstift overheen.</li>
                 <li>Uw lippen hebben geen bleke kleur meer.</li>
                 <li>Vervaagt geleidelijk.</li>
                 <li>Blijft 2-3 jaar goed zichtbaar.</li>
                </ul>
                <br/>
                <br/>
            </div>
            <div class="col-md-4">
                <img src="assets/images/lipblush.jpg" width="100%"/>
            </div>
        </div>


    </div>
</div>