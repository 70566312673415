import { Component, OnInit } from '@angular/core';
import * as url from 'url';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  public loaded = false;

  public loadScript() {
    const node = document.createElement('script');
    node.src = 'https://static-widget.salonized.com/loader.js';
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  constructor() { }

  ngOnInit(): void {
    this.loadScript();
    this.loaded = true;
  }

}
