import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './pages/home/home.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { AboutComponent } from './pages/about/about.component';
import { SalonComponent } from './pages/salon/salon.component';
import { ContactComponent } from './pages/contact/contact.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { FooterComponent } from './components/footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {LightboxModule} from 'ngx-lightbox';
import { ContactComponentComponent } from './components/contact-component/contact-component.component';
import { SalonComponentComponent } from './components/salon-component/salon-component.component';
import { AppointmentComponent } from './pages/appointment/appointment.component';
import { BarberComponent } from './pages/barber/barber.component';
import { MakeupComponent } from './pages/makeup/makeup.component';
import { LaserComponent } from './pages/laser/laser.component';
import { FitnessComponent } from './pages/fitness/fitness.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PricingComponent,
    AboutComponent,
    SalonComponent,
    ContactComponent,
    NavigationComponent,
    FooterComponent,
    ContactComponentComponent,
    SalonComponentComponent,
    AppointmentComponent,
    BarberComponent,
    MakeupComponent,
    LaserComponent,
    FitnessComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
